import cx from 'classnames';

import { StoryHeaderData } from '@hubcms/domain-story';
import { StoryHeadline, StoryHeadlineProps } from './StoryHeadline';
import { StoryLabels } from './StoryLabels';

import styles from './story-title.module.scss';

type StoryTitleProps = Pick<StoryHeaderData, 'articleId' | 'headingData' | 'label' | 'sublabel'> & {
  className?: string;
  isPremium?: boolean;
  headingProps?: Pick<StoryHeadlineProps, 'className' | 'id' | 'level' | 'size'>;
};

export function StoryTitle({
  headingData,
  label = '',
  sublabel = '',
  articleId,
  className,
  isPremium = false,
  headingProps,
}: StoryTitleProps) {
  return (
    <div className={cx(styles.storyTitle, className)}>
      <StoryLabels label={label} sublabel={sublabel} isPremium={isPremium} />
      {headingData?.text && <StoryHeadline articleId={articleId} {...headingData} isPremium={isPremium} {...headingProps} />}
    </div>
  );
}
