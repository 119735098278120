import cx from 'classnames';

import styles from './story-header.module.scss';
import { StoryHeaderProps } from './types';

function StoryHeader({ className, children }: StoryHeaderProps) {
  return <hgroup className={cx(styles.storyHeader, className)}>{children}</hgroup>;
}

export default StoryHeader;
