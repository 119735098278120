import { OnPicture } from '@hubcms/domain-cook';

export type ImageOrientation = 'SIXTEEN_NINE' | 'TWENTYONE_NINE' | 'FOUR_FIVE' | 'BASE_WIDTH' | 'BASE_WIDTH_50' | 'THREE_TWO';
// TODO: CW-92 there is already ImageAspectRatio, is it the same?
type AspectRatio = Exclude<keyof OnPicture['fields'], 'com_escenic_defaultmetadata'>;
type ImageOrientationData = [AspectRatio, number, number];

export const ImageOrientationMap: Record<ImageOrientation, ImageOrientationData> = {
  BASE_WIDTH: ['baseWidth', 1, 1],
  BASE_WIDTH_50: ['baseWidth', 0.5, 0.5],
  SIXTEEN_NINE: ['sixteenNine', 16, 9],
  TWENTYONE_NINE: ['twentyoneNine', 21, 9],
  FOUR_FIVE: ['fourFive', 4, 5],
  THREE_TWO: ['threeTwo', 3, 2],
};
