import { Environment } from '@news-components/newsletter-subscription-widget';

type Environment = typeof Environment;

export const newsletterEnvironmentMap = new Map<string, Environment>([
  ['LOCAL', Environment.Test],
  ['DEVTEST', Environment.Test],
  ['TEST', Environment.Preview],
  ['STAGING', Environment.Preview],
  ['PROD', Environment.Production],
]);
